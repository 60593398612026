import React, { useEffect, useState } from "react";
import MultiInputBox from "./components/MultiInputBox";
import AccommodationDetails from "./components/AccommodationDetails";
import useAccommodationServices from "../../services/api-services/accommodationServices";
import icons_edit from "../../../assets/svgs/icons_edit-outline.svg";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";

const PrivateDetailModal = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [roomId, setRoomId] = useState(null);
  const [ratePerDay, setRatePerDay] = useState("");
  const [isActive, setIsActive] = useState(false);

  const {
    showModalPrivateDetail,
    setShowModalPrivateDetail,
    handleClosePrivateDetail,
    privateRoomDetailsData,
    setPrivateRoomDetailsData,
    privateRoomDetailsID,
    amenities,
    setAmenities,
    instructions,
    setInstructions,
    otherDetails,
    setOtherDetails,
    getPrivateRoomDetailsData,
    addIconChange
  } = props;

  const {
    postPrivateRoomId,
    postAccommodationDetails,
    getAccommodationDetails,
    deleteAccommodationDetails,
    postPrivateRoomsDetails,
  } = useAccommodationServices();

  useEffect(() => {
    setIsActive(addIconChange)
	  }, []);

    const isActiveChange = ()=>{
      setIsActive(prev=> !prev)
    }

  const getAccommodationDetailsList = async (params) => {
    try {
      console.log("accommodation details list ---- ", params);
      const response = await getAccommodationDetails(params);

      if (response?.success) {
        if (params.content_type == "amenity") {
          setAmenities(response?.data);
        } else if (params.content_type == "instruction") {
          setInstructions(response?.data);
        } else if (params.content_type == "other") {
          setOtherDetails(response?.data);
        }
      }
    } catch {}
  };

  const addAccommodationDetails = async (
    value,
    accommodation_type,
    content_type,
    fk_ward,
    fk_private_room
  ) => {
    try {
      const data = JSON.stringify({
        content: value,
        fk_ward: fk_ward,
        fk_private_room: fk_private_room,
        accomodation_type: accommodation_type,
        content_type: content_type,
      });

      console.log("accommodation details----sending_data------", data);
      const response = await postAccommodationDetails(data);
      if (response?.success) {
        getPrivateRoomDetailsData();
      } else {
      }
    } catch {}
  };

  const deleteAccommodationDetailsContent = async (
    id,
    accommodation_type,
    content_type,
    fk_ward,
    fk_private_room
  ) => {
    try {
      const response = await deleteAccommodationDetails(id);

      if (response?.success) {
        getPrivateRoomDetailsData();
      }
    } catch {}
  };
  // private_rooms/details/create/
  const handleSubmitRatePerDay = async () => {
    try {
      const data = JSON.stringify({
        room_type_name: "Private Room",
        air_conditioned: false,
        rate_per_day: ratePerDay,
        private_type: "private",
      });
      const response = await postPrivateRoomsDetails(data);
      if (response?.success) {
        console.log(response, "=======success");
        setRoomId(response?.data?.id);
      } else {
        console.log(response, "====no success");
      }
    } catch (error) {
      console.log(error, "error=========");
    }
  };
  // console.log(roomId,"=========roomId");
  // console.log(privateRoomDetailsID,"===privateRoomDetailsID");

  return (
    <div>
      {showModalPrivateDetail && (
        <Modal
          show={true}
          centered={true}
          onHide={() => setShowModalPrivateDetail(false)}
          size="xl"
          contentClassName="ward-details"
        >
          <Modal.Body>
            <div className="modal-header ward-addition  modal-top  mt-3 px-3 ">
              <h3 className="modal-title">Private Room Details</h3>
              {isActive ?
               (
                <div className="d-flex">
                <button
                  className="p-2 btn  px-4 d-flex "
                  style={{border:"1px solid rgb(61, 75, 232)", color:"rgb(61, 75, 232)"}}
                  // onClick={() => setShowModalPrivateDetail(false)}
                  onClick={() => isActiveChange()}
                >
                  Cancel
                </button>
                <button
                  className="p-2 btn btn-primary px-4 d-flex ms-2"
                  // onClick={() => setShowModalPrivateDetail(false)}
                >
                  Save
                </button>
                </div>
              ): (
                <button
                  className="p-1 pr-detail-edit px-3 d-flex justify-content-around align-items-center"
                  // onClick={() => setShowModalPrivateDetail(false)}
                  onClick={() => isActiveChange()}
                  style={{color:"#154674"}}
                >
                  <div className="icons-edit-div">
                    <img src={icons_edit} className="me-3" alt="" />
                  </div>
                  Edit
                </button>
              )}
            </div>
            <div className="row mt-3 p-3">
              <div className="col-md-6">
                {/* <div className="col-md-6"> */}
                <label htmlFor="" className="modal-label">
                  Rate Per Day
                </label>
                <div className="p-2 mb-2 border bg-light rounded">
                  <input
                    type="text"
                    name="rate_per_day"
                    disabled={!isActive}
                    value={ratePerDay}
                    // value={privateRoomDetailsData?.rate_per_day}
                    onBlur={handleSubmitRatePerDay}
                    onChange={(e) => setRatePerDay(e.target.value)}
                    className="w-100 h-100"
                  />
                </div>
                <div className="mb-3" style={{ height: "50px" }}>
                  <MultiInputBox
                    label={"Available Amenities"}
                    // data={value?.currentMedicalRecords && value?.currentMedicalRecords}
                    data={amenities}
                    onAdd={(value) =>
                      addAccommodationDetails(
                        value,
                        "room",
                        "amenity",
                        null,
                        privateRoomDetailsID
                      )
                    }
                    onRemove={(id) =>
                      deleteAccommodationDetailsContent(
                        id,
                        "room",
                        "amenity",
                        null,
                        privateRoomDetailsID
                      )
                    }
                    containerClass={"col-12 mb-3"}
                    icon={"+"}
                    disabled={!isActive}
                  />
                </div>

                <label htmlFor="" className="mt-5 modal-label">
                  Other Details
                </label>
                <div>
                  <AccommodationDetails
                    data={otherDetails}
                    onAdd={(value) =>
                      addAccommodationDetails(
                        value,
                        "room",
                        "other",
                        null,
                        privateRoomDetailsID
                      )
                    }
                    onRemove={(id) =>
                      deleteAccommodationDetailsContent(
                        id,
                        "room",
                        "other",
                        null,
                        privateRoomDetailsID
                      )
                    }
                    iconCol={true}
                    disabled={!isActive}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="" className="modal-label">
                  Instructions
                </label>
                <div className="" style={{ height: "90%" }}>
                  <AccommodationDetails
                    data={instructions}
                    onAdd={(value) =>
                      addAccommodationDetails(
                        value,
                        "room",
                        "instruction",
                        null,
                        privateRoomDetailsID
                      )
                    }
                    onRemove={(id) =>
                      deleteAccommodationDetailsContent(
                        id,
                        "room",
                        "instruction",
                        null,
                        privateRoomDetailsID
                      )
                    }
                    iconCol={true}
                    disabled={!isActive}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default PrivateDetailModal;
