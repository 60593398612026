import React, { useEffect, useState } from "react";
// import React, { useEffect, useState } from 'react'
import { Form, Tab, TabPane, Nav } from "react-bootstrap";
import "./Ipadmissions.css";
import Swal from "sweetalert2";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";
import { MdOutlineModeEditOutline } from "react-icons/md";
import HashLoader from "react-spinners/ClockLoader";
import AdmissionPagination from "../reception/patient/patient-list/components/AdmissionPagination";
// import useConsultationServices from "../../../../services/api-services/consultationServices";
import useInpatientServices from "../../../../src/app/services/api-services/inpatientServices";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { GoPlusCircle } from "react-icons/go";


const Admission = (props) => {
	const {
		IPAdmissionList, loading,
		status, isMd, setIsMd,
		setStatus
	} = props;

	useEffect(() => {
		setStatus(status)
	}, [
		// pagination.page,
		// sortBy,
		// search,
		// filtered,
		// selectedDate,
		// fromDate,
		// toDate,
		// filterType,
	]);

	return (
		<>
			<Tab.Container activeKey={"tab1"}>
				<div className="patient-list-container">
					<Tab.Content className="patient-table-container container-white">
						<TabPane eventKey="tab1" className="">
							<div className="table-responsive">
								<table className="table patient-list-table px-2 ">
									<thead className="patient-list-table-head">
										<tr>
											<th style={{ width: 0 }}>Sl.no</th>
											<th style={{ width: 0 }}>Patient ID</th>
											<th style={{ width: 0 }}>IP Number</th>
											<th style={{ width: 0 }}>Patient Name</th>
											<th style={{ width: 0 }}>Room/W.no</th>
											<th style={{ width: 0 }}>Incharge Physician</th>
											<th style={{ width: 0 }}>Admitted Date</th>
											<th style={{ width: 0 }}>Status</th>
											<th style={{ width: 0 }}></th>
											{isMd && (
												<th className="text-center" style={{ width: 0 }}>
													Action
												</th>
											)}
										</tr>
									</thead>

									{loading ? (
										<tbody>
											<tr>
												<td colSpan="7">
													<div className="d-flex justify-content-center align-items-center loader-container">
														<HashLoader color="#e3b075" size={100} />
													</div>
												</td>
											</tr>
										</tbody>
									) : (
										<>
											<tbody className="patient-list-table-body">
												{IPAdmissionList &&
													IPAdmissionList.map((item, index) => {
														return (
															<tr key={index} className="position-relative">
																<td>
																	<label className="appointment-list-text">
																		{index + 1}
																	</label>
																</td>
																<td>
																	<label className="appointment-list-text patient-id-color">
																		{item?.patient_info?.patient_id}
																	</label>
																</td>

																<td>
																	<label className="appointment-list-text patient-id-color">
																		{item?.ip_number}
																	</label>
																</td>

																{/* <td onClick={() => handleShowAppointment(item)}> */}
																<td>
																	<label
																		className="appointment-list-text text-uppercase text-wrap"
																		role="button"
																	>
																		{item?.patient_info?.full_name}
																	</label>
																</td>
																<td>
																	<label className="appointment-list-text">
																		{
																			`${
																				item?.patient_accommodation_details?.accommodation_type == 'ward' ? 
																				item?.patient_accommodation_details?.bed_detail?.fk_ward_details?.ward_name+'/'+item?.patient_accommodation_details?.bed_detail?.bed_no 
																				: item?.patient_accommodation_details?.accommodation_type == 'room' ? 
																				item?.patient_accommodation_details?.private_room_detail?.room_name+'('+item?.patient_accommodation_details?.private_room_detail?.room_number+')'
																				: 'Not Available'
																			}`
																		}
																	</label>
																</td>
																<td>
																	<label className="appointment-list-text">
																		{item?.physician_info?.first_name}
																	</label>
																</td>
																<td >
																	<label className="appointment-list-text">
																		{item?.admitted_date &&
																			moment(new Date(item?.admitted_date)).format(
																			"DD/MM/YYYY"
																			)}
																	</label>
																</td>
																<td>
																		{
																			item?.admission_status == 'pending' ?
																			<button className='admit-btn'><GoPlusCircle className='fs-4'/> Admit</button> :
																			<span className='admitted-text'>Admitted</span>
																		}
																</td>
																<td>
																	<div className='btn'><BsInfoCircle className='fs-4'/> </div>
																</td>
																<td>
																	<div className='d-flex justify-content-center align-items-center'>
																		<div className='btn'><BsThreeDotsVertical className='fs-3'/></div>
																	</div>
																</td>
																{/* {activeDE && (
                                <div className="d-flex gap-3 position-absolute" style={{right:"60px"}}><span
                                className="appointment-edit-btn"
                                // onClick={() => handleAppointmentEdit(item)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                              <span
                                  className="appointment-del-btn"
                                  // onClick={() =>
                                  //   handleDeleteWithConfirmation(item)
                                  // }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </div>
                              )} */}
															</tr>
														);
													})}
											</tbody>
										</>
									)}

								</table>
							</div>
						</TabPane>
					</Tab.Content>
				</div>
			</Tab.Container>
		</>
	);
};

export default Admission;
