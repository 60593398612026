import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import './TextInput.css'

const InputTextBox = (props) => {
    const {
        label,
        required,
        containerClass,
        placeholder,
        type,
        visible,
        disabled,
        onChange,
        maxLength,
        hint,
        hintId,
        defvalue,
        pattern,
        title,
        removeMb,
        sizePad,
    } = props
    const [tempValue, setTempValue] = useState('')
    const [inputType, setInputType] = useState("password")
    const [isValid, setIsValid] = useState(true) // State for validation
    useEffect(() => {
        setTempValue(defvalue)
    }, [defvalue])
    const handleChange = (e) => {
        const value = e.target.value
        setTempValue(value)

        // Pattern validation
        if (pattern) {
            const regex = new RegExp(pattern)
            setIsValid(value.length < 1 || regex.test(value))
        }

        try {
            onChange(value)
        } catch { }
    }
    const handleEye = () => {
        inputType === 'password' ? setInputType('text') : setInputType('password');
    }
    return (
        <Form.Group
            className={containerClass}
        >
            <Form.Label
                className='label-text-content'
            >
                {label && label}
                {required && <span className='text-danger'> *</span>}
            </Form.Label>
            {(type === 'password'&&visible)?
                <InputGroup>
                    <Form.Control
                        type={inputType}
                        value={tempValue}
                        className='input-password-control'
                        required={required ? required : false}
                        disabled={disabled ? disabled : false}
                        placeholder={placeholder || ''}
                        onChange={handleChange}
                        aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text
                        id="basic-addon1"
                        className="input-password-eye"
                        onClick={handleEye}
                        style={{ height: '2.3rem' }}
                    >
                        {inputType === 'password' ?
                            <AiOutlineEye /> :
                            <AiOutlineEyeInvisible />
                        }
                    </InputGroup.Text>
                </InputGroup> :

                <Form.Control
                    type={type?type:'text'}
                    value={tempValue}
                    className={`input-text-control ${sizePad ? "p-4" : ""} ${removeMb ? 'mb-0' : ''}`} 
                    maxLength={maxLength?maxLength:null}
                    required={required ? required : false}
                    disabled={disabled ? disabled : false}
                    placeholder={placeholder || ''}
                    onChange={handleChange}
                    aria-describedby={hintId?hintId:null}
                    pattern={pattern}
                    title={title}
                    isInvalid={!isValid}
                />
                
            }
            {hint&&<Form.Text id={hintId} muted>{hint}</Form.Text>}
            {!isValid && <Form.Text className="text-danger">{title || 'Invalid input'}</Form.Text>}
       
        </Form.Group>
    )
}

export default InputTextBox
