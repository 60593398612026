import React, { useEffect, useState } from "react";
import { Tab, TabPane } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";
import { BsThreeDotsVertical } from "react-icons/bs";

const LeaveList = (params) => {
  const {
    allLeaveRequests,
    delLeaveRequest,
    getallLeaveRequests,
    setPhysician,
    setLeaveReason,
    setToDate,
    setFromDate,
    setDays,
    setIsDisabled,
    setEditID,
    setAllLeaveRequests,
  } = params;

  const [isMd, setIsMd] = useState(window.innerWidth >= 768);
  const [activeDE, setActiveDE] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMd(true);
      } else {
        setIsMd(false);
      }
    };

    // Add the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLeaveEdit = (data) => {
    setPhysician(data.fk_employee);
    setLeaveReason(data.remark);
    setToDate(data.to_date);
    setFromDate(data.from_date);
    setDays(data.no_days);
    setEditID(data.id);
    setIsDisabled(false);
  };

  const handleLeaveDel = async (id) => {
    try {
      const response = await delLeaveRequest(id);
      if (response.success) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        setAllLeaveRequests([]);
        getallLeaveRequests();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleDeleteWithConfirmation = (data) => {
    Swal.fire({
      title: "Are you sure?",
      h5: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonh5: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleLeaveDel(data.id);
      }
    });
  };
  console.log("##################", allLeaveRequests);
  return (
    <div>
      <Tab.Container activeKey={"tab1"}>
        <div className="patient-list-container p-0">
          <Tab.Content className="patient-table-container container-white">
            <TabPane eventKey="tab1" className="">
              <div className="table-responsive">
                <table className="table patient-list-table">
                  <thead className="patient-list-table-head">
                    <tr>
                      <th>No</th>
                      <th>Date</th>
                      <th>Physican Name</th>
                      <th>Number of Days</th>
                      <th>Leave reason</th>
                      <th style={{ width: 0 }}></th>
                      {isMd && <th style={{ width: 0 }}></th>}
                    </tr>
                  </thead>
                  <tbody className="patient-list-table-body">
                    {allLeaveRequests !== null &&
                      allLeaveRequests &&
                      allLeaveRequests.map((item, index) => {
                        return (
                          <tr key={index} className="position-relative">
                            <td>
                              <label className="appointment-id-text">
                                {item?.id}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text text-uppercase text-wrap appointmenttextdata">
                                {item?.from_date.split().reverse().join("-")}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text">
                                {item?.physician_name}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-token-text text-center">
                                {item?.no_days}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text">
                                {item?.remark}
                              </label>
                            </td>
                            {isMd ? (
                              <td className="">
                                <span
                                  className="appointment-edit-btn"
                                  onClick={() => handleLeaveEdit(item)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                              </td>
                            ) : (
                              <td>
                                <BsThreeDotsVertical
                                  onClick={() => setActiveDE((prev) => !prev)}
                                />
                              </td>
                            )}
                            {isMd && (
                              <td className="">
                                <span
                                  className="appointment-del-btn"
                                  onClick={() =>
                                    handleDeleteWithConfirmation(item)
                                  }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </td>
                            )}

                            {activeDE && (
                              <div
                                className="d-flex gap-3 position-absolute"
                                style={{ right: "60px" }}
                              >
                                <span
                                  className="appointment-edit-btn"
                                  onClick={() => handleLeaveEdit(item)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                                <span
                                  className="appointment-del-btn"
                                  onClick={() =>
                                    handleDeleteWithConfirmation(item)
                                  }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </div>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </TabPane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default LeaveList;
