import axios from "axios";

// Local Server
// export const BASE_URL = "http://localhost:8000/api/v1/"
// export const MEDIA_URL = "http://localhost:8000"

// Pranavam Server
export const BASE_URL = "https://pranavam-beta-api.foxa.in/api/v1/";
export const MEDIA_URL = "https://pranavam-beta-api.foxa.in";

// export const BASE_URL = "http://139.59.59.160:8000/api/v1/";
// export const MEDIA_URL = "https://pranavam-api.foxa.in";
// Pranavam Server
// export const BASE_URL = "http://192.168.1.24:8000/api/v1/"
// export const MEDIA_URL = "http://192.168.1.24:8000"

// export const BASE_URL = "https://pranavam-beta-api.foxa.in/api/v1/";
// export const MEDIA_URL = "https://pranavam-beta-api.foxa.in";

// export const BASE_URL = "https://api.pranavamerp.com/api/v1/";
// export const MEDIA_URL = "https://api.pranavamerp.com";

// Pranavam Server
// export const BASE_URL = "http://192.168.1.31:8000/api/v1/"
// export const MEDIA_URL = "http://192.168.1.31:8000"

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
