import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import MultiInputBox from "../MultiInputBox";
import AccommodationDetails from "../AccommodationDetails";

import useAccommodationServices from "../../../../services/api-services/accommodationServices";
import useAccountServices from "../../../../services/api-services/accountServices";
import "react-bootstrap";


const WardFrom = (props) => {
    const { 
        postWardAddition,
        postAccommodationDetails,
		deleteAccommodationDetails 
    } = useAccommodationServices()
    const { getNewID } = useAccountServices()

    const {
        setAddWard,
        handleAddWard,
        setWardId,
        wardId,
        wardDetails,
        setWardDetails,	
        setShowModal,
        addWard,
        getAllWardData
    } = props;

    const handleChangeWardAddition = (e) => {
		const { name, value } = e.target;
		if (name === "eligible_for_insurance") {
			setWardDetails((prev) => ({
				...prev,
				[name]: !prev.eligible_for_insurance,
			}));
		} else {    
			setWardDetails((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	useEffect(() => {
		getWardId()
	}, [])

	const getWardId = async () => {
		try {
			const response = await getNewID("ward_id")
			if (response?.success) {
				setWardId(response?.data?.complete_id)
			}
		} catch { }
	}

	const handleSubmitWardAddition = async () => {

		try {
			const data = JSON.stringify(wardDetails)
			const response = await postWardAddition(data)
			if (response?.success) {
				Swal.fire('', response?.message, 'success')
			}
			else {
				Swal.fire('', response?.message, 'error')
			}
		} catch (error) {
			console.log(error, "error=========");

		}
	};


	const addAccommodationDetails = async (value, accommodation_type, content_type, fk_ward, fk_private_room) => {
        try {
            const data = JSON.stringify({
                content: value,
				fk_ward: fk_ward,
				fk_private_room: fk_private_room,
				accomodation_type: accommodation_type,
				content_type: content_type
            })

			console.log('accommodation details----sending_data------', data);
            const response = await postAccommodationDetails(data)
            if (response?.success) {
				getAllWardData()
            }
            else {
				
            }
        } catch { }
    }

	const deleteAccommodationDetailsContent = async (id, accommodation_type, content_type, fk_ward, fk_private_room) => {
		try {
			const response = await deleteAccommodationDetails(id)

			if (response?.success) {
				getAllWardData()
			}
		} catch { }
	}


    return (
        <>
            <div className="w-100 h-100 px-4">
                <div className="modal-header ward-addition  modal-top  mt-3 px-3 ">
                    <h3 className="modal-title">Ward Details</h3>
                    {
                        (addWard) ?
                        <div>
                            <button className="p-2 btn btn-primary px-4" onClick={() => {setShowModal(false); setAddWard(false)}}>
                                Cancel
                            </button>
                            <button onClick={() =>{ handleSubmitWardAddition(); setAddWard(false)}} className="p-2 btn btn-outline-primary px-4 ms-3">Save</button>
                        </div> 
                        :
                        <button className="p-2 btn btn-primary px-4" onClick={() => {setShowModal(false); setAddWard(false)}}>
                            Close
                        </button>
                    }                    
                </div>

                <div className="w-100 " role="document">
                    <div className="modal-content border-0  p-4   ">
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Ward ID
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <input type="text" name="ward_id" value={!addWard ? wardDetails.ward_id : wardId} onChange={handleChangeWardAddition} className="w-100 h-100" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Ward Name
                                </label>
                                <div className="p-2 border bg-light rounded ">
                                    <input type="text" name="ward_name" value={!addWard ? wardDetails?.ward_name : ''} onChange={handleChangeWardAddition} className="w-100 h-100" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Ward Type
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <select
                                        onChange={handleChangeWardAddition}
                                        name="ward_type"
                                        className="w-100 h-100 border-0 outline-0 ward-detail-select"
                                        style={{
                                            appearance: "none",
                                            background: "none",
                                            padding: "0",
                                            margin: "0",
                                            boxShadow: "none",
                                            outline: "none",
                                        }}
                                    >
                                        <option value="">Select </option>
                                        <option value="general" selected={!addWard && wardDetails.ward_type=='general' ? true : false}>General</option>
                                        <option value="special" selected={!addWard && wardDetails.ward_type=='general' ? true : false}>Special</option>
                                    </select>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Rate Per Day
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <input type="text" value={!addWard ? wardDetails.rate_per_day : ''} onChange={handleChangeWardAddition} name="rate_per_day" className="w-100 h-100" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Total Bed
                                </label>
                                <div className="p-2 border bg-light rounded">
                                    <input type="text" onChange={handleChangeWardAddition} value={!addWard ? wardDetails.total_no_of_bed : ''} name="total_no_of_bed" className="w-100 h-100" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor=""></label>
                                <div className="p-2  d-flex align-items-center ">
                                    <input type="checkbox" onChange={handleChangeWardAddition} checked={!addWard && wardDetails.eligible_for_insurance} name="eligible_for_insurance" className="me-2" />
                                    <label htmlFor="" className="modal-label">
                                        Eligible for insurance
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <MultiInputBox
                                    label={'Available Amenities'}
                                    data={!addWard && wardDetails?.amenities ? wardDetails?.amenities:  [] }
                                    onAdd={(value)=>addAccommodationDetails(
                                        value, 
                                        'ward', 
                                        'amenity', 
                                        wardDetails?.id,
                                        null
                                    )}
                                    onRemove={(id)=>deleteAccommodationDetailsContent(
                                        id, 
                                        'ward',
                                        'amenity', 
                                        wardDetails?.id,
                                        null, 
                                    )}
                                    containerClass={'col-12 mb-3'}
                                    icon={'+'}
                                />
                                <label htmlFor="" className="mt-2 modal-label">
                                    Other Details
                                </label>
                                <div
                                    className=""
                                >
                                    <AccommodationDetails
											data={!addWard && wardDetails?.other_details ? wardDetails?.other_details : []}
											onAdd={(value)=>addAccommodationDetails(
												value, 
												'ward', 
												'other', 
												wardDetails?.id,
												null
											)}
											onRemove={(id)=>deleteAccommodationDetailsContent(
												id, 
												'ward',
												'other', 
												wardDetails?.id,
												null 
											)}
											iconCol={true}
										/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="" className="modal-label">
                                    Instructions
                                </label>
                                <div
                                    className=""
                                >
                                    <AccommodationDetails
                                        data={!addWard && wardDetails?.instructions ? wardDetails?.instructions : []}
                                        onAdd={(value)=>addAccommodationDetails(
                                            value, 
                                            'ward', 
                                            'instruction', 
                                            wardDetails?.id,
                                            null
                                        )}
                                        onRemove={(id)=>deleteAccommodationDetailsContent(
                                            id, 
                                            'ward',
                                            'instruction', 
                                            wardDetails?.id,
                                            null
                                        )}
                                        iconCol={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WardFrom


