import React from 'react'
import AvailableIcon from '../../../../../assets/svgs/room_green.svg' 
import ReservedIcon from '../../../../../assets/svgs/room_yellow.svg' 
import OccupiedIcon from '../../../../../assets/svgs/room_orange.svg' 

const AdmRoomBed = ({handleRoomSelection,totalRoomCost,days,value}) => {
    return(
    <div className='row'>
    <div className="col-8 p-3">
      <h4>Room List</h4>
      <div className="row mb-4" style={{height:"28rem",overflowY:"scroll"}}>
        {value?.roomList.map((room,i) => (
          <div className="col-4 p-3 px-5 pt-5" key={i}>
            <div
              className={`col-12 border border-2 rounded-3 bed-card ${value?.selectedRoom === room?.id ? 'border-primary' : '' }`}
              onClick={() => handleRoomSelection(room)}
              style={{border: value.selectedRoom === room.id ? '2px solid blue' : '', cursor: room?.room_status === 'available' ? 'pointer' : 'help'}}
            >
            <span className='move-icon'>
                {room?.room_status === 'available' && <img src={AvailableIcon} width={60} height={60} />}
                {room?.room_status === 'reserved'&&<img src={ReservedIcon} width={60} height={60}/>}
                {room?.room_status === 'occupied'&&<img src={OccupiedIcon} width={60} height={60}/>}
            </span>
            <div className='row text-center'>
                <h6 className='col-12 p-2 pt-4 pb-0'>{room?.room_number}</h6>
                <h6 className='col-12'>{room?.room_name.toUpperCase()[0]+room?.room_name?.slice(1)?.toLowerCase()}</h6>
                <div className='col-12 p-2 d-flex justify-content-center'>
                <div className={`col-4 p-2 rounded-3  ${ room?.room_status === 'available'? 'available-bed': room?.room_status === 'reserved'? 'reserved-bed': 'occupied-bed' }`}>
                {room?.room_status}
                </div>
                </div>
            </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    
    <div className="col-4 p-4 py-3 ps-3 rounded-3" style={{backgroundColor:'#FAFAFA'}}>
      <h4>Room Details</h4>
        <div className="row mb-3">
          <span className='col-4'>Room ID</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.selectedRoomDetails?.room_id} disabled/>
        </div>
        <div className="row mb-3">
          <span className='col-4'>Room Name</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.selectedRoomDetails?.room_name} disabled/>
        </div>
        <div className=" row mb-3">
          <span className='col-4'>Room Number</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.selectedRoomDetails?.room_number} disabled/>
        </div>
        <div className="row mb-3">
          <span className='col-4'>Floor</span>
          <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.selectedRoomDetails?.floor} disabled />
        </div>
        <div className="col-12 mb-3 d-flex p-1">
          <input type="checkbox" className='col-1' checked={value?.singlePrivateRoom?.eligible_for_insurance} disabled/> 
          <span className='col text-start'>Eligible for Insurance</span>
        </div>
        <div className=" row mb-3">
            <div className='col-12 d-flex justify-content-between' >
            <span className='col-6 text-start p-1'>Date of Discharge</span>
            <span className='col-6 text-end p-1'>Rate per Day</span>
            </div>
            <div className='col-12 d-flex justify-content-between' >
            <span className='col-6 text-start p-1'>
                  <input
                    className='input-text-control p-1'
                    type="date"
                    value={value.dischargeDate}
                    disabled
                  />
                </span>
                <span className='col-6 text-end p-1'>₹{value?.singlePrivateRoom?.rate_per_day.toFixed(2)}</span>
            </div>
        </div>
        <hr />
        <h5 className='text-end p-2 fs-5 fw-400' style={{color:"#E3B075"}}><span className='text-secondary fs-6 fw-200'>No of Days : {days}</span><br/>Total : ₹{totalRoomCost.toFixed(2)}</h5>
    </div>

</div>
)
}

export default AdmRoomBed