import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Accordion from '../../../shared/form-components/accordian/Accordion';
import useConsultationServices from '../../../services/api-services/consultationServices';

const AdmDiagnosisDetails = ({handleNext,handleBack,patientData}) => {
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [ashtaChoice,setAshtaChoice] = useState([])
    const [vitalTypes,setVitalTypes] = useState([])

    const toggleModal_Ashta = () => {
        setIsModalOpen1(!isModalOpen1);
    };
    const toggleModal_Health = () => {
        setIsModalOpen2(!isModalOpen2);
    };

    const{getAshtaChoice,getVitalTypes} = useConsultationServices()
    const getAshtaChoices = async() => {
        try{
            const response = await getAshtaChoice()
            if (response?.success){
                // console.log(response?.data?.types_choices)
                setAshtaChoice(response?.data?.types_choices)
            }
        }catch(err){
            console.log(err?.response)
        }
    }
    const getVitalTypeDetails = async ()=>{
        try{
            const response= await getVitalTypes()
            if(response?.success){
                setVitalTypes(response?.data?.filter((item)=>item.active===true))
            }
        }catch(err){
            console.log(err.response)
        }
    }
    useEffect (()=>{
        getVitalTypeDetails()
        getAshtaChoices()
    },[])
  return (
    <div>      
        <div className="container-fluid mt-4 p-4 ps-3">
            <h4 className=''>Diagnosis</h4>
            <hr />
            <div className="row mb-3">
                <div className='col-6'>
                    <div className='col-12 p-1 d-flex justify-content-between'>
                        <div className="col-6 p-1">
                        <label>Admission Date</label>
                        <input type="text" className="form-control" placeholder="Admission Date" value={patientData?.admission_date} disabled />
                        </div>
                        <div className="col-6 p-1 ">
                        <label>Duration</label>
                        <input type="text" className="form-control" placeholder="Duration" value={patientData?.duration}  disabled />
                        </div>
                    </div>
                    <div className="col-12 p-1">
                    <label>Reason for Admission</label>
                    <input type="text" className="form-control" placeholder="Reason for Admission" value={patientData?.reason_for_admission}  disabled />
                    </div>
                    <div className="col-12 p-1">
                    <label>Present Issue</label>
                    <input type="text" className="form-control" placeholder="Present Issue" value={patientData?.assessment_details?.present_issue} disabled />
                    </div>
                    <label className='p-1' >Symptoms</label>
                    <div className="col-12 p-3 rounded-3" style={{height:"10rem",overflowY:"scroll",backgroundColor:"#f4f4f4"}}>
                    { patientData?.symptoms.map((data)=>{
                        return(<input type="text" className="form-control" placeholder="Fever" disabled value={data}/>)
                    })}
                    </div>
                    <div className="col-12 p-1">
                    <label>ICD-10 Code</label>
                    <input type="text" className="form-control" placeholder="Remark" value={patientData?.assessment_details?.icd_10_code} disabled />
                    </div>
                </div>
                <div className='col-6'>
                    <div className='col-12 p-1'>
                        <label>Prakriti Assessment</label>
                        <div className="row">
                            <div className="col">
                            <input type="text" className="form-control" placeholder="Vatta" value={patientData?.assessment_details?.vatta} disabled />
                            </div>
                            <div className="col">
                            <input type="text" className="form-control" placeholder="Pitta" value={patientData?.assessment_details?.pita} disabled />
                            </div>
                            <div className="col">
                            <input type="text" className="form-control" placeholder="Kapha" value={patientData?.assessment_details?.kapha} disabled />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                    <label>Remark</label>
                    <textarea className="form-control" style={{ resize: 'none',backgroundColor:"#f4f4f4" }}  rows="2" placeholder="Remark" disabled>{patientData?.assessment_details?.remark}</textarea>
                    </div>
                    <div className="col-12 p-1">
                        <div className="row">
                            <div className='col-6'>
                                <div className='btn w-100' style={{backgroundColor:"#545D6E" ,color:"#fafafa"}} onClick={toggleModal_Ashta}>Ashtavidha Pariksha</div>
                            </div>
                            <div className='col-6'>
                                <div className='btn w-100' style={{backgroundColor:"#545D6E" ,color:"#fafafa"}} onClick={toggleModal_Health}>Health Assessment</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-1">
                    <label>Diagnosis Summary</label>
                    <textarea className="form-control"  style={{ resize: 'none',backgroundColor:"#f4f4f4" }} rows="4" placeholder="Summary content here..." disabled>{patientData?.assessment_details?.diagnosis}</textarea>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid mt-4 p-4 ps-3'>
            <div className='co1-12 d-flex justify-content-end p-2'>
                <div className='buttonviewdown' onClick={handleBack}>Back</div>
                <div className='buttonviewdown'  onClick={handleNext}>Next</div>
            </div>
        </div>
        
        <Modal show={isModalOpen1} onHide={toggleModal_Ashta} backdrop={true} keyboard={false} size="lg"  centered>
        <Modal.Header closeButton style={{backgroundColor:"#e3b075",color:"#fafafa"}}>
        <Modal.Title>{"Ashtavidha Pariksha"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"80vh"}}>
        <Accordion
                title={"Ashtavidha Pariksha"}
                data={ashtaChoice}
                data2={patientData?.ashtavidha_pariraksha}
                alwaysOn={true}
        />
        <div></div>
        </Modal.Body>
    </Modal>

    <Modal show={isModalOpen2} onHide={toggleModal_Health} backdrop={true} keyboard={false}  size="lg" centered>
        <Modal.Header closeButton style={{backgroundColor:"#e3b075",color:"#fafafa"}}>
        <Modal.Title>{"Health Assessment"}</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{height:"80vh"}}>
        <Accordion
            title={"Health Assessment"}
            data={vitalTypes}
            data2={patientData?.health_assessment}
            alwaysOn={true}
        />
        </Modal.Body>
    </Modal>
    </div>
  )
}

export default AdmDiagnosisDetails