import React, { useEffect, useState } from "react";
import Admission from "../IP Admissions/Admission";
import AdmissionRequest from "../IP Admissions/AdmissionRequest";
import Ward from "./Ward";
import PrivateRoom from "./PrivateRoom";
import "./Accommodation.css";
import WardForm from './components/ward/WardForm'

const Accommodation = () => {
	const [activeRequest, setActiveRequest] = useState(true);
    const [wardId, setWardId] = useState(null);
	const [wardDetails, setWardDetails] = useState({
        ward_name: "",
        ward_type: "",
        rate_per_day: "",
        total_no_of_bed: "",
        eligible_for_insurance: false,
    });

	// State to track the active tab
	const [activeTab, setActiveTab] = useState("tab1");

	// Function to change the active tab
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	return (
		<>
			{
				 (
					<div className="tabs-container mt-0">
						{/* Tab headers */}
						<div className="tabs tab-btn my-4">
							<button
								className={`px-5 ${activeTab === "tab1" ? "active" : ""} ${activeRequest ? "active-request" : ""
									}`}
								onClick={() => {
									handleTabClick("tab1");
									setActiveRequest(true);
								}}
							>
								Ward
							</button>
							<button
								className={`px-4 ${activeTab === "tab2" ? "active" : ""} ${activeRequest ? "" : "active-request"
									}`}
								onClick={() => {
									handleTabClick("tab2");
									setActiveRequest(false);
								}}
							>
								Private Room
							</button>
						</div>

						{/* Tab content */}
						<div className="tab-content">
							{
								activeTab === "tab1" &&
								<Ward 
									wardId={wardId} 
									setWardId={setWardId}
									wardDetails={wardDetails}
									setWardDetails={setWardDetails}
								/>
							}
							{activeTab === "tab2" && <PrivateRoom />}
						</div>
					</div>
				)}
		</>
	);
};

export default Accommodation;
