import React, { useEffect, useRef, useState } from 'react'

const AccommodationDetails = (props) => {
    const { 
        data,
        onAdd,
        onRemove,
        plusBtn,
        iconCol,
        disabled
    } = props
    // const [addShow, setAddShow] = useState(plusBtn?false:true)
    const [content, setContent] = useState('')

    const symptomsInput = useRef(null)

    // console.log(symptomsInput)

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        onAdd(content)
        e.target.value = ''
        setContent('')
        // setAddShow(false)
    }
    useEffect(()=>{
        // setAddShow(true)
    },[iconCol])

    const handleShow =()=>{
        // setAddShow(!addShow)
        setTimeout(()=>{

            // console.log(symptomsInput.current)
            symptomsInput?.current?.focus()
        },200)
    }

    function removeTag(item) {
        onRemove(item)
    }
    useEffect(()=>{
        if(!plusBtn && data.length > 0){
            // setAddShow(false)
        }
    },[plusBtn,data])
    return (
        <div className={`patient-symptoms-container row ${plusBtn?'p-1':''} mx-0 `}>
            <div className={`patient-symptoms-content ${plusBtn||iconCol?'col-12':'col-11'} row mx-0`}>
                <div className="content d-flex" style={{flexDirection:"column"}}>
                    {<input
                        ref={symptomsInput}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                        rows={'2'}
                        className="patient-symptoms-input"
                        placeholder={'Type Here ...'}
                        disabled={disabled}
                    />}
                    <br />
                    {data&&data.map((content, index) => (
                        <div className={`patient-symptoms-data`}  key={index}>
                            <div className="col-11 text">{content.content}</div>
                            <div className='col-1 remove' onClick={() => removeTag(content.id)}>+</div>
                        </div>
                    ))}

                </div>
            </div>
            {!plusBtn||!iconCol && <div
                className='col-1 d-flex justify-content-end px-0'
            >
                <div
                    className='icon-input-btn btn'
                    // onClick={handleShow}
                >
                    {/* {addShow?"-":"+"} */}
                    
                </div>
            </div>}
        </div>
    )
}

export default AccommodationDetails
