import React, { useEffect, useState } from 'react'
import AdmWardBed from './admBookingComponents/AdmWardBed';
import AdmRoomBed from './admBookingComponents/AdmRoomBed';
const AdmBooking = ({handleBack,handleNext,patientData,value,setValue}) => {
    const [selectedBedDetails,setSelectedBedDetails] = useState()
    const [totalRoomCost, setTotalRoomCost] = useState(0);

    const handleSelectWard = (id) => {
		setValue?.setSelectedWard(id);
		const result = value?.allWard.filter(data => data.id == id)
		setValue?.setSingleWard(result[0])
		setValue?.setBedList(result[0]?.beds)

	};
    
    const handleBedSelection = (bed) => {
        if (bed.bed_status === 'available') {
        setValue?.setSelectedBed(bed.id);
        setSelectedBedDetails(bed)
        }
    };

    const handleRoomSelection = (room) => {
        if (room.room_status === 'available') {
        setValue?.setSelectedRoom(room.id);
        setValue?.setSelectedRoomDetails(room)
        }
    };

    const handleSelectButton = (data) =>{
        setValue?.setActiveBtn(data)
    };

    useEffect(()=>{
        const today = new Date();
        const calculatedDischargeDate = new Date(today.setDate(today.getDate() + patientData?.duration));
        setValue?.setDischargeDate(calculatedDischargeDate.toISOString().split('T')[0]); 
    },[])

    
    useEffect(() => {
      const roomRate = value?.singlePrivateRoom?.rate_per_day || 0;
      setTotalRoomCost(patientData?.duration * roomRate);
    }, [patientData?.duration, value?.singlePrivateRoom]);
  return (
  <>
    <div className='col-12 p-2 ps-4 pe-5 pb-0 d-flex justify-content-start align-items-center mb-1'>
        <button className={`col-2 col-3 p-2 me-2 rounded-3 primary-bg  ${value?.activeBtn === 'ward' ? 'active' : ''}`}  onClick={()=>handleSelectButton("ward")}>Ward</button>
        <button className={`col-2 col-3 p-2 rounded-3 primary-bg  ${value?.activeBtn === 'room' ? 'active' : ''}`}  onClick={()=>handleSelectButton("room")}>Private Room</button>
        {/* <button className={`col-2 col-3 p-2 rounded-3 primary-bg  ${activeBtn === 'suit' ? 'active' : ''}`}  onClick={()=>handleSelectButton("suit")}>Private Suit</button> */}
        {/* <button className={`col-2 col-3 p-2 rounded-3 primary-bg  ${activeBtn === 'special' ? 'active' : ''}`}  onClick={()=>handleSelectButton("special")}>Specilaized Room</button> */}
    </div>
    {value?.activeBtn === 'ward' &&  <div className='col-12 p-3 ps-4 pe-5 pb-0 d-flex align-items-center '>
        {
            value?.allWard?.map((data, index) => (
                <p className={`d-flex align-items-center p-3 py-0 mb-0 all-ward-name ${value?.selectedWard === data.id ? 'selected-ward' : ''}`}
                    key={data.id}
                    onClick={() => handleSelectWard(data.id)}>
                    {data.ward_name}
                </p>
            ))
        }
    </div> }

    {/* {activeBtn === 'room' &&  <div className='col-12 p-2 ps-4 pe-5 pb-0 d-flex justify-content-between align-items-center mb-1'>
        {
            allPrivateRoom?.map((data, index) => (
                <p className={`d-flex align-items-center mb-0 all-ward-name ${selectedPrivateRoom === data.id ? 'selected-ward' : ''}`}
                    key={data.id}
                    onClick={() => handleSelectRoom(data.id)}>
                    {data.ward_name}
                </p>
            ))
        }
    </div>} */}



    <div className="container-fluid mt-4 p-4 ps-3">
   
      <div className="row">
        {value?.activeBtn === 'ward' &&  value?.selectedWard && <AdmWardBed {...{
            handleBedSelection,
        }}
        days={patientData?.duration}
        value={value}
        />}
        {value?.activeBtn === 'room' &&  <AdmRoomBed {...{
            handleRoomSelection,
            totalRoomCost, 
        }}
        days={patientData?.duration}
        value={value}
        />}
      </div>
    

      <div className='row'>
        <div className='co1-12 d-flex justify-content-end p-2'>
            <div className='buttonviewdown' onClick={handleBack}>Back</div>
            <div className='buttonviewdown'  onClick={handleNext}>Next</div>
        </div>
        </div>
      
    </div>
    </>
  );
};

export default AdmBooking