import React, { useEffect, useState } from 'react'
import useAccommodationServices from '../../services/api-services/accommodationServices'
import useAccountServices from '../../services/api-services/accountServices';
import Swal from 'sweetalert2';

const PrivateAddModal = (props) => {
	// postPivateRoomAddition
	const [privateRoomId, setPrivateRoomId] = useState(null);
	const { postPrivateRoom } = useAccommodationServices()
	const { getNewID } = useAccountServices()

	const {
		showModalPrivateAdd,
		privateRoomData,
		setPrivateRoomData,
		handleClosePrivateAdd,
		getPrivateRoomDetailsData
	} = props;


	const getPrivateRoomId = async () => {
		try {
			if (privateRoomData?.room_id) {
				setPrivateRoomId(privateRoomData.room_id);
			} else {
				const response = await getNewID("room_id")
				if (response?.success) {
					setPrivateRoomId(response?.data?.complete_id)
				}
			}
		} catch { }
	}


	const handleChangePrivateRoomAddition = (e) => {
		const { name, value } = e.target;

		setPrivateRoomData((prev) => ({
			...prev,
			[name]: value,
		}));
		

	};

	const handleSubmitPrivateRoomAddition = async () => {
		try {
			const data = JSON.stringify(privateRoomData)
			const response = await postPrivateRoom(data)
			if (response?.success) {
				Swal.fire('', response?.message, 'success')
				getPrivateRoomDetailsData()
				setPrivateRoomData({
					room_id: "",
					room_name: "",
					room_type: "",
					room_number: "",
				})
				getPrivateRoomId();

			}
			else {
				Swal.fire('', response?.message, 'error')
			}
		} catch (error) {

		}

	};

	useEffect(() => {
		getPrivateRoomId();
	}, [])


	return (
		<div>
			{showModalPrivateAdd && (
				<div
					className="modal show d-flex justify-content-center align-items-center"
					style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }} // Ensure height is full viewport
					tabIndex="-1"
					role="dialog"
				>
					<div className="w-50" role="document">
						<div className="modal-content  px-4" style={{ height: "400px" }}>
							<div className="modal-header">
								<h5 className="modal-title">New Room</h5>
							</div>

							<div className="row mt-3">
								<div className="col-md-12">
									<label htmlFor="" className='modal-label' >Room ID</label>
									<div className="p-2 border bg-light rounded">
										<input type="text" name='room_id' value={privateRoomId} onChange={handleChangePrivateRoomAddition} className="w-100 h-100" disabled />
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									<label htmlFor="" className='modal-label' >Room Name</label>
									<div className="p-2 border bg-light rounded">
										<input type="text" name="room_name" value={privateRoomData?.room_name} onChange={handleChangePrivateRoomAddition} className="w-100 h-100" />
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									<label htmlFor="" className='modal-label' >Floor</label>
									<div className="p-2 border bg-light rounded">
										<input type="text" name="floor" value={privateRoomData?.floor} onChange={handleChangePrivateRoomAddition} className="w-100 h-100" />
									</div>
								</div>
							</div>
							{/* <div className="row mt-3">
								<div className="col-md-12">
									<label htmlFor="" className='modal-label' >Ward Type</label>
									<div className="p-2 border bg-light rounded">
										<select
											className="w-100 h-100 border-0 outline-0 ward-detail-select"
											name="room_type"
											value={privateRoomData?.ward_type}
											onChange={handleChangePrivateRoomAddition}
											style={{
												appearance: "none",
												background: "none",
												padding: "0",
												margin: "0",
												boxShadow: "none",
												outline: "none",
											}}
										>
											<option value="">Select</option>
											<option value="first floor">First Floor</option>
											<option value="ground floor">Ground Floor</option>
										</select>
									</div>
								</div>
							</div> */}

							<div className="row mt-3">
								<div className="col-md-12">
									<label htmlFor="" className='modal-label' >Room No</label>
									<div className="p-2 border bg-light rounded">
										<input type="text" name="room_number" value={privateRoomData?.room_number} onChange={handleChangePrivateRoomAddition} className="w-100 h-100" />
									</div>
								</div>
							</div>

							<div className="modal-footer" style={{ borderTop: "0px solid" }}>
								<button className='private-add-cancel px-5 py-2 border border-primary' onClick={handleClosePrivateAdd}>Cancel</button>
								<button className='private-add-save border border-primary px-5 py-2' onClick={handleSubmitPrivateRoomAddition} >Save</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default PrivateAddModal
