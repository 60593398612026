import React from 'react'
import PatientSymptoms from '../../PatientSymptoms'
import PropertyDropDownBox from './DropProperty/PropertyDropDownBox'

const DietList = ({value,setValue,functions,subfunctions}) => {
  const sample = []
  return (
    <div className='p-5 pt-0'>
        <div className='col-12 d-flex justify-content-between'>
            <div className='col-6 p-1'>
            <label className='label-text-content'>Restricted</label>
            <PropertyDropDownBox
              state = {"restricted_food"} 
              setnew = {functions?.addPatientProperty}
              options ={value?.dietRestrictedNameList}
              setData = {setValue?.setDietRestricted}
              selectedValue ={value?.dietRestricted}
              listData={value?.dietRestrictedListData}
              remove={subfunctions?.handleDeleteRestricted}
              handleAdd={subfunctions?.handleAddRestricted}
            />
            </div>
            <div className='col-6 p-1'>
            <label className='label-text-content'>Recommented</label>
            <PropertyDropDownBox  
            state = {"recommended_food"} 
            setnew = {functions?.addPatientProperty}
            options ={value?.dietRecommentedNameList}
            setData = {setValue?.setDietRecommented}
            selectedValue ={value?.dietRecommented}
            listData={value?.dietRecommentedListData}
            remove={subfunctions?.handleDeleteRecommented}
            handleAdd={subfunctions?.handleAddRecommented}
            />
            </div>
        </div>
        <div className='col-12'>
        <label className='label-text-content'>Instructions</label>
        <PatientSymptoms
            onAdd={subfunctions?.handleAddDietInstructions}
            data={value?.dietInstructionList}
            onRemove={subfunctions?.handleDeleteDietInstructions}
            iconCol={true}
            plusBtn={true}
        />
        </div>
    </div>
  )
}

export default DietList