import patient_details from "../../../../../assets/pictures/icons/patient-details-icon.png";
import physician_add from "../../../../../assets/pictures/icons/physician-add-icon.png";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import usePatientServices from "../../../../services/api-services/patientServices";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Form, Nav, Tab, TabPane } from "react-bootstrap";
import "./AppointmentList.css";
import CheckBoxBtn from "../../../../shared/form-components/checkbox/CheckBoxBtn";
import useConsultationServices from "../../../../services/api-services/consultationServices";
import FilterSortPagination from "../../patient/patient-list/components/FilterSortPagination";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Modal } from "antd";
import profilepic from "../../../../../assets/pictures/images/sample_profile.png";
import { MEDIA_URL } from "../../../../axios";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import HashLoader from "react-spinners/ClockLoader";
import { BsThreeDotsVertical } from "react-icons/bs";
import Pagination from "../../patient/patient-list/components/Pagination";


const AppointmentList = () => {
  const [appointmentList, setAppointmentList] = useState();
  const [filterType, setFilterType] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    item: 10,
    totalPages: 1,
  });
  const today = moment();
  const [sortBy, setSortBy] = useState("");
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [fromDate, setFromDate] = useState(""); // New state for range start date
  const [toDate, setToDate] = useState(""); // New state for range end date

  const [showAppointment, setShowAppointment] = useState(false);
  const [appDetails, setAppDetails] = useState("");
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMd, setIsMd] = useState(window.innerWidth >= 768);
  const [activeDE, setActiveDE] = useState(false);

  const navigate = useNavigate();

  const {
    getAppointmentList,
    delPatientAppointment,
    getPatientDetail,
    getSortFilterPagination,
  } = useConsultationServices();

  // useEffect(() => {
  //     getAppointments()
  // }, [filterType])

  // const getAppointments = async () => {
  //     try {
  //         var param = {
  //             status: filterType
  //         }
  //         const response = await getAppointmentList(param)
  //         if (response?.success) {
  //             setAppointmentList(response?.data)
  //         }
  //         else {
  //             Swal.fire("", response?.message, "error")
  //         }
  //     } catch { }
  // }

  const handleAppointmentEdit = (data) => {
    if (data?.is_registered_patient) {
      navigate("/appointment-add", {
        state: {
          id: data?.id,
        },
      });
    } else {
      navigate("/patient-register", {
        state: {
          appointment_id: data?.id,
          patient_id: data?.fk_temp_patient,
        },
      });
    }
  };

  const handleFilterChange = (status) => {
    setFilterType(status);
    setDropdownOpen(false); // Close the dropdown
  };

  const filterAppointment = [
    { label: "All", value: "" },
    { label: "Draft", value: "draft" },
    { label: "Confirmed", value: "confirmed" },
    { label: "In Consultation", value: "consulting" },
    { label: "In Observation", value: "in_observation" },
    { label: "Completed", value: "completed" },
    { label: "Cancelled", value: "cancelled" },
  ];

  const getAppointmentListData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await getSortFilterPagination({
        item: pagination.item,
        page: pagination.page,
        sort_by: sortBy,
        search: search,
        type: filtered,
        from_date: fromDate, // Send fromDate to API
        to_date: toDate, // Send toDate to API
        status: filterType,
        date: selectedDate,
        // accessToken: localStorage.getItem("accessToken"),
      });

      if (response?.success) {
        setAppointmentList(response?.data?.appointments);
        setPagination((prev) => ({
          ...prev,
          totalPages: response?.data?.total_page,
        }));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    getAppointmentListData();
  }, [
    pagination.page,
    sortBy,
    search,
    filtered,
    selectedDate,
    fromDate,
    toDate,
    filterType,
  ]);


    useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMd(true);
      } else {
        setIsMd(false);
      }
    };

    // Add the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filterOptions = [];

  const handleFilterChange2 = (filter) => {
    console.log("Filter selected:", filter);
    setFiltered(filter); // Update the filtered state with the selected filter
    setFromDate("");
    setSelectedDate("");
    setToDate("");
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    console.log("Search query:", query);
    setSearch(query);
    // Implement search logic here
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      console.log("Formatted Date:", formattedDate);
      setSelectedDate(formattedDate); // Update the selectedDate state
      setFromDate("");
      setFiltered("");
      setToDate("");
    } else {
      setSelectedDate(""); // Clear the selected date if no date is selected
    }
  };

  const handleRangeDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(moment(start).format("YYYY-MM-DD")); // Update the fromDate state
      setToDate(moment(end).format("YYYY-MM-DD")); // Update the toDate state
      setFiltered("");
      setSelectedDate("");
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const sortOptions = [
    {
      label: (
        <>
          Token Number
          <span className="ps-2">
            <ArrowUpwardIcon />
          </span>
        </>
      ),
      value: "token_number_asc",
    },
    {
      label: (
        <>
          Token Number
          <span className="ps-2">
            <ArrowDownwardIcon />
          </span>
        </>
      ),
      value: "token_number_desc",
    },
    {
      label: (
        <>
          Patient Name{" "}
          <span className="ps-2">
            <ArrowUpwardIcon />
          </span>
        </>
      ),
      value: "patient_name_asc",
    },
    {
      label: (
        <>
          Patient Name
          <span className="ps-2">
            <ArrowDownwardIcon />
          </span>
        </>
      ),
      value: "patient_name_desc",
    },
    {
      label: (
        <>
          Appointment Id
          <span className="ps-2">
            <ArrowUpwardIcon />
          </span>
        </>
      ),
      value: "appointment_id_asc",
    },
    {
      label: (
        <>
          Appontment Id
          <span className="ps-2">
            <ArrowDownwardIcon />
          </span>
        </>
      ),
      value: "appointment_id_desc",
    },
    {
      label: (
        <>
          Specification
          <span className="ps-2">
            <ArrowUpwardIcon />
          </span>
        </>
      ),
      value: "specification_asc",
    },
    {
      label: (
        <>
          Specification
          <span className="ps-2">
            <ArrowDownwardIcon />
          </span>
        </>
      ),
      value: "specifcation_desc",
    },
  ];

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy); // Update the sort_by state
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleAppointmentDel = async (id) => {
    try {
      const response = await delPatientAppointment(id);
      if (response.success) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        getAppointmentListData();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleDeleteWithConfirmation = (data) => {
    Swal.fire({
      title: "Are you sure?",
      h5: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonh5: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleAppointmentDel(data.id);
      }
    });
  };

  const handleShowAppointment = async (item) => {
    try {
      const response = await getPatientDetail(item.id);
      if (response.success) {
        setAppDetails(response?.data);
        setShowAppointment(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log(appDetails);

  const onhandleReset = async () => {
    setPagination({
      page: 1,
      item: 10,
      totalPages: 1,
    });
    setSortBy("");
    setSearch("");
    setFiltered("");
    setSelectedDate("");
    setFromDate("");
    setToDate("");
    setResponse(true);
    setFilterType("");
    getAppointmentListData();
  };

  return (
    <div className="">
      <Modal
        title={null}
        centered
        open={showAppointment}
        width={1000}
        footer={null}
        onCancel={() => setShowAppointment(false)}
      >
        <div className="rounded p-3">
          <div className="row">
            <div className="col-6">
              <div style={{ padding: "47px 10px" }} className="row">
                <div className="col-6 d-flex flex-column justify-content-center align-items-start">
                  <h3 style={{ color: "#1585d8" }} className="m-1">
                    #{appDetails.token}
                  </h3>
                  <h2 className="m-1">
                    {appDetails?.patient_details?.name.toUpperCase()}
                  </h2>
                </div>
                <div className="col-6 d-flex flex-column justify-content-center align-items-start">
                  <h4 className="m-1">
                    {appDetails?.patient_details?.mobile || "Mobile Number"}
                  </h4>
                  <h4 className="m-1">
                    {appDetails?.patient_details?.email || "E-mail ID"}
                  </h4>
                  <h4 className="m-1">
                    {moment(
                      appDetails?.patient_details?.details?.created_at
                    ).format("DD/MM/YYYY")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                style={{
                  backgroundColor: "#e6a14d",
                  borderRadius: 10,
                  padding: "47px 10px",
                  color: "white",
                }}
                className="row"
              >
                <div className="col-2 col-3 d-flex justify-content-center align-items-center">
                  <img
                    src={MEDIA_URL + appDetails.physician_details?.image}
                    alt="Profile"
                    style={{
                      border: "1px solid black",
                      borderRadius: "50%",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </div>
                <div className="col-5 d-flex flex-column justify-content-center align-items-start">
                  <h3 className="m-1">{appDetails?.physician_details?.name}</h3>
                  <h4 className="m-1">
                    {appDetails?.physician_details?.phy_id}
                  </h4>
                </div>
                <div className="col-4 col-5 d-flex flex-column justify-content-center align-items-end">
                  <h4 className="m-1">{appDetails?.speciality}</h4>
                  <h4 className="m-1">
                    {appDetails?.physician_details?.qualification.join(",") ||
                      "BAMS"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "24px" }} className="row">
            <div className="col-6">
              <h5 className="m-2">
                Admission Date:{" "}
                {moment(
                  appDetails?.patient_details?.details?.created_at
                ).format("DD/MM/YYYY")}
              </h5>
              <h5 className="m-2">
                Appointment No: {appDetails?.appointment_id || "0000"}
              </h5>
              <h5 className="m-2">
                Invoice No: {appDetails?.invoice_number || "0000"}
              </h5>
            </div>
            <div className="col-6">
              <h5 className="m-2">
                Patient Type:{" "}
                {appDetails?.patient_details?.details?.patient_type.toUpperCase() ||
                  "Native"}
              </h5>
              <h5 className="m-2">
                Address: {appDetails?.patient_details?.details?.district}{" "}
                {appDetails?.patient_details?.details?.country}
              </h5>
            </div>
          </div>
          <div className="col-12 mt-3 px-2 text-center">
            <div className="row align-items-center">
              <h5 className="col-4">Shift</h5>
              <h5 className="col-4">Date</h5>
              <h5 className="col-4">Time</h5>
            </div>
            <div
              className="row align-items-center rounded"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <h5 className="col-4 mb-0 py-2">
                {moment(appDetails?.shift?.from_time, "HH:mm:ss").format(
                  "hh:mm A"
                )}{" "}
                to{" "}
                {moment(appDetails?.shift?.to_time, "HH:mm:ss").format(
                  "hh:mm A"
                )}
              </h5>
              <h5 className="col-4 mb-0 py-2">
                {appDetails?.appointment_date?.split("-").reverse().join("/")}
              </h5>
              <h5 className="col-4 mb-0 py-2">
                {moment(appDetails?.created_at).format("hh:mm A")}
              </h5>
            </div>
          </div>
        </div>
      </Modal>

      <Tab.Container activeKey={"tab1"}>
        <div className="col-12">
          <Nav className="tabpane-container">
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab1">
                <img src={patient_details} className="details-icon" alt="" />
                &nbsp; Appointment List
              </Nav.Link>
            </Nav.Item>
            <div className="d-flex justify-content-end col pe-3">
              <div
                className="tabpane-extra-btns"
                onClick={() => navigate("/appointment-add")}
              >
                <img
                  src={physician_add}
                  className="physician-add-icon"
                  alt=""
                />
                &nbsp;&nbsp; Make Appointment
              </div>
            </div>
          </Nav>
        </div>
        <div className="appointmentList-sub-tabpane row">
          <div className="appointmentList-filter-container row mx-0">
            <FilterSortPagination
              filterOptions={filterOptions}
              sortOptions={sortOptions}
              onFilterSelect={handleFilterChange2}
              onSortSelect={handleSortChange}
              onSearchChange={handleSearchChange}
              onPageChange={handlePageChange}
              pagination={pagination}
              handleDateChange={handleDateChange} // Ensure this is passed down
              handleRangeDateChange={handleRangeDateChange} // Date range
              filterAppointment={filterAppointment}
              onFilterAppointment={handleFilterChange}
              filterType={filterType}
              handleReset={onhandleReset}
              response={response}
              setResponse={setResponse}
              search={search}
            />
          </div>
          <span className="col-3 col-4"></span>
        </div>
        
        <div className="patient-list-container">
          <Tab.Content className="patient-table-container container-white">
            <TabPane eventKey="tab1" className="">
            <div className="table-responsive">
              <table className="table patient-list-table px-2 ">
                <thead className="patient-list-table-head">
                  <tr>
                    <th>Appointment No</th>
                    <th>Patient Name</th>
                    <th>Phone Number</th>
                    <th>Token</th>
                    <th>Specification</th>
                    <th style={{ width: 0 }}>Status</th>
                    <th style={{ width: 0 }}></th>
                    {isMd && <th className="" style={{ width: 0 }} ></th>}
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="d-flex justify-content-center align-items-center loader-container">
                          <HashLoader color="#e3b075" size={100} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <tbody className="patient-list-table-body">
                      {appointmentList &&
                        appointmentList.map((item, index) => {
                          return (
                            <tr key={index} className="position-relative">
                              <td>
                                <label className="appointment-id-text">
                                  {item?.appointment_id}
                                </label>
                              </td>
                              <td onClick={() => handleShowAppointment(item)}>
                                <label
                                  className="appointment-list-text text-uppercase text-wrap appointmenttextdata"
                                  role="button"
                                >
                                  {item?.patient_details?.name}
                                </label>
                              </td>
                              <td>
                                <label className="appointment-list-text">
                                  {item?.patient_details?.mobile}
                                </label>
                              </td>
                              <td>
                                <label className="appointment-token-text">
                                  {item?.token}
                                </label>
                              </td>
                              <td>
                                <label className="appointment-list-text">
                                  {item?.speciality}
                                </label>
                              </td>
                              <td className="">
                                <Form.Select
                                  className="appointment-status-dropdown"
                                  value={item?.status}
                                >
                                  <option value="draft">Draft</option>
                                  <option value="confirmed">Confirmed</option>
                                  <option value="consulting">
                                    In Consultation
                                  </option>
                                  <option value="in_observation">
                                    In Observation
                                  </option>
                                  <option value="completed">Completed</option>
                                  <option value="cancelled">Cancelled</option>
                                </Form.Select>
                              </td>
                              {isMd ? <td className="">
                                <span
                                  className="appointment-edit-btn"
                                  onClick={() => handleAppointmentEdit(item)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                              </td>:<td><BsThreeDotsVertical onClick={()=>setActiveDE(prev=>!prev)} /></td>}
                              {isMd && <td className="">
                                <span
                                  className="appointment-del-btn"
                                  onClick={() =>
                                    handleDeleteWithConfirmation(item)
                                  }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </td>}
                              {activeDE && (
                                <div className="d-flex gap-3 position-absolute" style={{right:"60px"}}><span
                                className="appointment-edit-btn"
                                onClick={() => handleAppointmentEdit(item)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                              <span
                                  className="appointment-del-btn"
                                  onClick={() =>
                                    handleDeleteWithConfirmation(item)
                                  }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </div>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}
              </table>
              </div>
            </TabPane>
          </Tab.Content>
        </div>
      </Tab.Container>

      <div className="d-block d-md-none pb-2 d-flex justify-content-end">
    <Pagination
          filterOptions={filterOptions}
          sortOptions={sortOptions}
          onFilterSelect={handleFilterChange}
          onSortSelect={handleSortChange}
          onSearchChange={handleSearchChange}
          onPageChange={handlePageChange}
          pagination={pagination}
          handleDateChange={handleDateChange} // Ensure this is passed down
          handleRangeDateChange={handleRangeDateChange} // Date range
          handleReset={onhandleReset}
          response={response}
          setResponse={setResponse}
          search={search}
        />
    </div>
    </div>
  );
};

export default AppointmentList;
