import React from 'react'

const PatientCountCard = ({title,count,img,subtitle,helpIcon}) => {
  return (
    <div className='container-white d-flex'>
      <div className='col-12'>
          <div className='col-12 d-flex justify-content-evenly align-items-center'>
            <div className="col-4 p-1 pe-0 img-container d-flex justify-content-end align-items-center rounded">
                <img src={img} alt="..."/>
            </div>
            <div className="col-8 p-1 ps-0 container-text d-flex flex-column align-items-start justify-content-center ">
                <div className="text-main">{title}</div>
                <div className="text-sub">{subtitle}</div>
            </div>
          </div>
          
          <div className='col-12 p-1 pt-3 d-flex justify-content-between align-items-center'>
            <div className='col-4 text-num d-flex justify-content-center align-items-center'>{count}</div>
            <div className='col-8 d-flex justify-content-end align-items-center rounded'>
              <img src={helpIcon} alt="..." className="img-fluid" style={{ Width: '30px', height: '30px'}}/>
            </div>
          </div>
      </div>
    </div>
  )
}

export default PatientCountCard