import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const useInpatientServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //GET IP Admission List : 
    const getIPAdmissionList = async(params)=>{
        const response = await axiosPrivate.get(
            `inpatient/ip/admission/list/`,
            {
              params: {...params},
            }
        ) 
        return response.data
    }

    const getIpPatientDetails = async(id)=>{
      const response = await axiosPrivate.get(
          `inpatient/ip/admission/patient/detail/${id}/`,
      ) 
      return response.data
  }

    //POST 
    const postTranferIp = async (id, data) => {
        const response = await axiosPrivate.post(
          `inpatient/transfer/${id}/`,
          data
        );
        return response.data;
      };

      const postIpAdmit = async (id, data) => {
        const response = await axiosPrivate.post(
          `inpatient/admit/${id}/`,
          data
        );
        return response.data;
      };

    return {
        //get
        getIPAdmissionList,
        getIpPatientDetails,
        //post
        postTranferIp,
        postIpAdmit,
    }
}

export default useInpatientServices;