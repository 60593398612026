import React from "react";
import DropDownBox from "../../../../../../shared/form-components/dropdown/CustomDropDown";
import TextAreaBox from "../../../../../../shared/form-components/form-textarea/TextAreaBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {FaRegSquarePlus} from "react-icons/fa6";
import { MdOutlineModeEdit } from "react-icons/md";
import "./MedicineList.css";
const MedicineList = ({ value, setValue, functions }) => {

  const handleSelectMedicine = (item, i) => {
    setValue?.setSelectedMedicine(i);
    setValue?.setMedName(item.fk_medicine_name)
    setValue?.setMedStrength(item.fk_strength)
    setValue?.setMedDosage(item.fk_dosage)
    setValue?.setMedDose(item.fk_dose)
    setValue?.setMedFrequency(item.fk_frequency)
    setValue?.setMedQuantity(item.fk_total_quantity)
    setValue?.setMedDuration(item.fk_duration)
    setValue?.setMedRemark(item.remark)
    setValue?.setSlnoMed(i+1)
    setValue?.setMedId(item.id)
  };

  return (
    <div className="container medicine mx-0 w-100">
      <table style={{ "text-align": "center" }}>
        <tr>
          <th>
            <div>Sl.No</div>
          </th>
          <th>
            <div>Medicine Name</div>
          </th>
          <th>
            <div>Strength/Unit</div>
          </th>
          <th>
            <div>Dosage (Form)</div>
          </th>
          <th>
            <div>Dose (Metric Measure)</div>
          </th>
          <th>
            <div>Frequancy & Timing</div>
          </th>
          <th>
            <div>Total Quantity</div>
          </th>
          <th>
            <div>Duration</div>
          </th>
          <th>
            <div>Remark</div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
        <tr>
          <td>
            <div className="custom_input1 d-flex align-items-center justify-content-center">
              {value?.slnoMed}
            </div>
          </td>
          <td>
            <div className="fixed-width-dropdown-med1">
              <DropDownBox
                containerClass={"w-100 d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"medicine_name"}
                setNew={functions?.addPatientProperty}
                options={value?.medicineNameList}
                setDataValue={setValue?.setMedName}
                selectedValue={value?.medName}
              />
            </div>
          </td>
          <td>
          
            <div className="fixed-width-dropdown-med2">
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"strength"}
                setNew={functions?.addPatientProperty}
                options={value?.medStrengthList}
                setDataValue={setValue?.setMedStrength}
                selectedValue={value?.medStrength}
              />
            </div>
          </td>
          <td>
            <div className="fixed-width-dropdown-med">
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"dosage"}
                setNew={functions?.addPatientProperty}
                options={value?.medDosageList}
                setDataValue={setValue?.setMedDosage}
                selectedValue={value?.medDosage}
              />
            </div>
          </td>
         
          <td>
            <div className="fixed-width-dropdown-med2">
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"dose"}
                setNew={functions?.addPatientProperty}
                options={value?.medDoseList}
                setDataValue={setValue?.setMedDose}
                selectedValue={value?.medDose}
              />
            </div>
          </td>
          <td>
            <div className="fixed-width-dropdown-med">
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"frequency"}
                setNew={functions?.addPatientProperty}
                options={value?.medFrequencyList}
                setDataValue={setValue?.setMedFrequency}
                selectedValue={value?.medFrequency}
              />
            </div>
          </td>
          <td>
            <div className="fixed-width-dropdown-med2">
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"total_quantity"}
                setNew={functions?.addPatientProperty}
                options={value?.medQuantityList}
                setDataValue={setValue?.setMedQuantity}
                selectedValue={value?.medQuantity}
              />
            </div>
          </td>
          <td>
            <div className="fixed-width-dropdown-med2">
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"duration"}
                setNew={functions?.addPatientProperty}
                options={value?.medDurationList}
                setDataValue={setValue?.setMedDuration}
                selectedValue={value?.medDuration}
              />
            </div>
          </td>
          <td>
            <div className="fixed-width-dropdown-med1">
              <TextAreaBox
                rows={"1"}
                containerClass={"d-flex align-items-center mb-0"}
                onChange={setValue?.setMedRemark}
                defvalue={value?.medRemark}
                removeMb={true}
              />
            </div>
          </td>
          <td>
            <button
              className="btn text-light"
              style={{ backgroundColor: "#E3B075" }}
              onClick={(e)=>functions?.handleSubmitMedicine(e)}
            >
             {value?.slnoMed ? <MdOutlineModeEdit  size={20}/> : <FaRegSquarePlus size={20}/>} 
            </button>
          </td>
        </tr>
        {value?.medicineListData?.length > 0 &&
          value?.medicineListData?.map((item, i) => (
            <tr>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-center">
                  {i + 1}
                </div>
              </td>
              <td>
                <div
                  className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start mouse-pointer"
                  style={
                    value?.selectedMedicine === i
                      ? { backgroundColor: "#e3b075" }
                      : {}
                  }
                  onClick={(e) => handleSelectMedicine(item, i)}
                >
                  {item?.medicine_name?.property_value}
                </div>
              </td>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
                  {item?.strength?.property_value}
                </div>
              </td>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
                  {item?.dosage?.property_value}
                </div>
              </td>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
                  {item?.dose?.property_value}
                </div>
              </td>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
                  {item?.frequency?.property_value}
                </div>
              </td>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
                  {item?.total_quantity?.property_value}
                </div>
              </td>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
                  {item?.duration?.property_value}
                </div>
              </td>
              <td>
                <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
                  {item?.remark}
                </div>
              </td>
              <td>
                <button
                  className="btn text-light"
                  onClick={() => functions?.handleMedicineDelete(item)}
                  style={{ backgroundColor: "#E3B075" }}
                >
                  <DeleteOutlineIcon size={20} />
                </button>
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default MedicineList;
