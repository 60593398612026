import React, { useEffect, useState } from 'react'
// import React, { useEffect, useState } from 'react'
import { Form, Tab, TabPane,Nav } from 'react-bootstrap';
import "./Ipadmissions.css";
import useInpatientServices from "../../../../src/app/services/api-services/inpatientServices";
import moment from "moment";
import Swal from "sweetalert2";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { MdOutlineModeEditOutline } from "react-icons/md";
import HashLoader from "react-spinners/ClockLoader";
import { GoPlusCircle } from "react-icons/go";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import AdmPatientDetails from './AdmProcessTabs/AdmPatientDetails';
import AdmDiagnosisDetails from './AdmProcessTabs/AdmDiagnosisDetails';
import AdmBooking from './AdmProcessTabs/AdmBooking';
import './AdmissionRequest.css';
import { error } from 'pdf-lib';
import AdmInsurance from './AdmProcessTabs/AdmInsurance';
import { useNavigate } from 'react-router-dom';
import useAccommodationServices from '../../services/api-services/accommodationServices';
const AdmissionRequest = (props) => {
  const {
    IPAdmissionList, loading,
    status, isMd, setIsMd,
    setStatus
  } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [patientData, setPatientData] = useState([]);
  const [selectedRoom,setSelectedRoom] = useState(null);
  const [selectedBed, setSelectedBed] = useState(null);
  const [activeBtn,setActiveBtn] = useState('ward');
  const [dischargeDate, setDischargeDate] = useState(null);

  const [allWard, setAllWard] = useState([]);
  const [selectedWard, setSelectedWard] = useState('');
  const [singleWard, setSingleWard] = useState({});
  const [bedList, setBedList] = useState([]);
  const [roomList,setRoomList] = useState([])
  const [singlePrivateRoom, setSinglePrivateRoom] =useState({});
  const [selectedRoomDetails,setSelectedRoomDetails] = useState()
  const [allPrivateRoom,setAllPrivateRoom] = useState([]);
  const [selectedPrivateRoom,setSelectedPrivateRoom]=useState('')

  const [providerName,setProviderName] =useState('')
  const [planName,setPlanName] =useState('')
  const [policyNumber,setPolicyNumber] =useState('')
  const [dob,setDob] =useState('')
  const [startDate,setStartDate] =useState('')
  const [endDate,setEndDate] =useState('')
  const [holderName,setHolderName] =useState('')
  const [holderContact,setHolderContact] =useState('')

  const {getIpPatientDetails,postIpAdmit} = useInpatientServices();
  const { getAllWard, getPrivateRoomDetails} = useAccommodationServices()
  const today = new Date().toISOString().split('T')[0];    
  const navigate = useNavigate()
  useEffect(() => {
    setStatus(status)
  }, [
    // pagination.page,
    // sortBy,
    // search,
    // filtered,
    // selectedDate,
    // fromDate,
    // toDate,
    // filterType,
  ]);
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    getAllWardData()
    getAllRoomData()
  }, [])

  const getAllWardData = async () => {
  try {
    const response = await getAllWard()
    if (response?.success) {
      setAllWard(response?.data)
      setSelectedWard(response?.data[0]?.id)
      setSingleWard(response?.data[0])
      setBedList(response?.data[0]?.beds)
    }
  } catch { }
  }

  const getAllRoomData = async () => {
  try {
    const response = await getPrivateRoomDetails()
    if (response?.success) {
      setAllPrivateRoom(response?.data)
      setSelectedPrivateRoom(response?.data[0]?.id)
      setSinglePrivateRoom(response?.data[0])
      setRoomList(response?.data[0]?.private_rooms)
    }
  } catch { }
  }

  const handleStartAdmissionProcess = async(id) => {
    try{
      const response = await getIpPatientDetails(id)
      if (response?.success){
        setPatientData(response?.data)
        Swal.fire({
          icon: 'success',          
          title: response.message,  
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 2000,
          timerProgressBar: true, 
          customClass: {
            popup: 'colored-toast'
          },
          background: '#f0f9eb', 
          showClass: { 
            popup: 'slide-in-right'
          },
          hideClass: { 
            popup: 'slide-out-left'
          },
        });
        setCurrentStep(2);
      }else{
        Swal.fire("Error",response.message,"error")
      }

    }catch(e){

    }
     
  };
  // const handleStartAdmissionProcess = ()=>{
  //   setCurrentStep(2);
  // }
  const handleSubmitAdmission = async()=>{
    let data={ admitted_date: patientData?.admission_date,
                accommodation_type:activeBtn,
                start_date:today,
                end_date:dischargeDate,
                provider_name:providerName,
                plan_name:planName,
                policy_number:policyNumber,
                holder_name:holderName,
                contact_number:holderContact,
                date_of_birth:dob,
                validity_start_date:startDate,
                validity_end_date:endDate,
                ...(activeBtn==='ward' && {fk_bed:selectedBed}),
                ...(activeBtn==='room' && {fk_room:selectedRoom})
    } 
    if (!(selectedBed || selectedRoom)) {
        Swal.fire("info","Select an accomodation","info")
        return
    }
    const response = await postIpAdmit(patientData?.id,data)
    if (response.success){
        handleNext()
        Swal.fire({
            icon: 'success',          
            title: response.message,  
            toast: true,
            position: 'top-end',
            showConfirmButton: false, 
            timer: 2000,
            timerProgressBar: true, 
            customClass: {
              popup: 'colored-toast'
            },
            background: '#f0f9eb', 
            showClass: { 
              popup: 'slide-in-right'
            },
            hideClass: { 
              popup: 'slide-out-left'
            },
          })
        navigate('/')
    }
}
  return (
    <div>
      {currentStep === 1 && (
    <Tab.Container activeKey={"tab1"}>
    <div className="patient-list-container">
          <Tab.Content className="patient-table-container container-white">
            <TabPane eventKey="tab1" className="">
            <div className="table-responsive">
              <table className="table patient-list-table px-2 ">
                <thead className="patient-list-table-head">
                  <tr>
                    <th style={{ width: 0 }}>Sl.no</th>
                    <th style={{ width: 0 }}>Patient ID</th>
                    <th style={{ width: 0 }}>Patient Name</th>
                    <th style={{ width: 0 }}>Transferred Physician</th>
                    <th style={{ width: 0 }}>Admitted Date</th>
                    {isMd && <th className="text-center" style={{ width: 0 }} >Action</th>}
                  </tr>
                </thead>

                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <div className="d-flex justify-content-center align-items-center loader-container">
                          <HashLoader color="#e3b075" size={100} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <tbody className="patient-list-table-body">
                      {IPAdmissionList &&
                        IPAdmissionList.map((item, index) => {
                          return (
                            <tr key={index} className="position-relative">
                              <td>
                                <label className="appointment-list-text">
                                  {index+1}
                                </label>
                              </td>

                              {/* <td onClick={() => handleShowAppointment(item)}> */}
                              <td>
                                <label
                                  className="appointment-list-text patient-id-color text-uppercase text-wrap "
                                  role="button"
                                >
                                  {item?.patient_info?.patient_id}
                                </label>
                              </td>
                              {/* <td>
                                <label className="appointment-list-text">
                                  {item?.patient_details?.mobile}
                                </label>
                              </td> */}
                              <td>
                                <label className="appointment-list-text">
                                  {item?.patient_info?.full_name}
                                </label>
                              </td>
                              <td>
                                <label className="appointment-list-text">
                                  {item?.physician_info?.first_name}
                                </label>
                              </td>
                              <td >
                                <label className="appointment-list-text">
                                  {item?.admission_date &&
                                    moment(new Date(item?.admission_date)).format(
                                      "DD/MM/YYYY"
                                    )}
                                </label>
                                
                              </td>
                              {/* <td className="">
                                {
                                  item?.admission_status == 'pending' ?
                                  <button>+ Admit</button> :
                                  <span>Admitted</span>
                                }
                              </td> */}
                              {/* {isMd ? <td className="">
                                <span
                                  className="appointment-edit-btn"
                                  // onClick={() => handleAppointmentEdit(item)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                              </td>:<td><BsThreeDotsVertical onClick={()=>setActiveDE(prev=>!prev)} /></td>} */}

                              <td>
                                <div className='d-flex justify-content-evenly align-items-center'>
                                      {
                                        item?.admission_status == 'pending' ?
                                        <button  onClick={()=>handleStartAdmissionProcess(item?.id)} className='admit-btn'><GoPlusCircle className='fs-4'/> Admit</button> :
                                        <span className='admitted-text'>Admitted</span>
                                      }
                                      <div className='btn'><BsInfoCircle className='fs-4'/> </div>
                                      <div className='btn'><BsThreeDotsVertical className='fs-3'/></div>
                                  </div>
                              </td>
                              {/* {isMd && <td className="">
                                <span
                                  className="appointment-del-btn"
                                  // onClick={() =>
                                  //   handleDeleteWithConfirmation(item)
                                  // }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </td>} */}
                              {/* {activeDE && (
                                <div className="d-flex gap-3 position-absolute" style={{right:"60px"}}><span
                                className="appointment-edit-btn"
                                // onClick={() => handleAppointmentEdit(item)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                              <span
                                  className="appointment-del-btn"
                                  // onClick={() =>
                                  //   handleDeleteWithConfirmation(item)
                                  // }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </div>
                              )} */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}

              </table>
              </div>
            </TabPane>
          </Tab.Content>
        </div>
        </Tab.Container>
  )}

      {currentStep === 2 && (
        <AdmPatientDetails 
        handleNext={handleNext} 
        handleBack={handleBack} 
        patientDetails={patientData?.patient_basic_info}
        />
      )}

      {currentStep === 3 && (
        <AdmDiagnosisDetails 
        handleNext={handleNext} 
        handleBack={handleBack} 
        patientData={patientData} 
        />
      )}

      {currentStep === 4 && (
        <AdmBooking 
        handleBack={handleBack}
        handleNext={handleNext} 
        patientData={patientData} 
        value={{
          activeBtn,
          selectedBed,
          selectedRoom,
          dischargeDate,
          allWard,
          selectedWard,
          singleWard,
          bedList,
          roomList,
          singlePrivateRoom,
          selectedRoomDetails,
          allPrivateRoom,
          selectedPrivateRoom
        }} 
        setValue={{
          setActiveBtn,
          setSelectedRoom,
          setSelectedBed,
          setDischargeDate,
          setAllWard,
          setSelectedWard,
          setSingleWard,
          setBedList,
          setRoomList,
          setSinglePrivateRoom,
          setSelectedRoomDetails,
          setAllPrivateRoom,
          setSelectedPrivateRoom,
        }}
        />
      )}

      {currentStep === 5 && (
        <AdmInsurance 
        handleBack={handleBack} 
        // handleNext={handleNext}
        handleSubmit={handleSubmitAdmission} 
        patientData={patientData}
        value={{
          providerName,
          planName,
          policyNumber,
          dob,
          startDate,
          endDate,
          holderName,
          holderContact,
        }}
        setValue={{
          setProviderName,
          setPlanName,
          setPolicyNumber,
          setDob,
          setStartDate,
          setEndDate,
          setHolderName,
          setHolderContact,
        }}
        />
      )}
</div>
)}

export default AdmissionRequest
