import React, { useEffect } from 'react'
import { useState } from 'react';
import "./Accordion.css" 

const Accordion = ({title, data, data2, setAttribute, setContent, attribute, content, type, handleAdd, handleVitalAdd ,handleDelete,alwaysOn}) => {

    const [isOpen, setIsOpen] = useState(alwaysOn)

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion ${isOpen ? 'open' : ''}`}>
             {!alwaysOn &&<div className="accordion-header" >
                <div className='col fw-100 fs-6'>{title}</div>
                <div className='col col-1 pe-0 text-end fw-bold fs-6' onClick={toggleAccordion}>+</div>
            </div>}
            {isOpen && (
                <div className="accordion-content " style={{height:"75vh"}}>
                    <div className='row mx-0'>
                        {data?.map((item)=>{
                            const subData = item?.vital_name?
                                data2?.filter((item2)=>item2.type===item?.id):
                                data2?.filter((item2)=>item2.type===item)
                            
                            return(
                                <>
                                    <div className='assessment-main col-3 px-0 mx-0 mb-2'>{item?.vital_name?item?.vital_name.toUpperCase():item.toUpperCase()}</div>
                                    <form className='col-9 row mx-0 pe-0 mb-2'>
                                        {subData&&subData.map((data, index)=>{
                                            return(
                                                <>
                                                    <div className='col-3 px-0 mb-1'>
                                                        <div className={`assessment-sub ${item?.vital_name?"blue":"pink"}`}>
                                                            {data?.attribute}
                                                        </div>
                                                    </div>
                                                    <div className='col-9 pe-0 mb-1'>
                                                        <div className='assessment-desc pe-0'>
                                                            <div>{data?.description}</div>
                                                            <div className="close" onClick={() => handleDelete(data?.id)}>+</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        <div className='col-3 px-0'>
                                            <div className={`assessment-sub ${item?.vital_name?"blue":"pink"}`}>
                                                <input 
                                                    type="text" 
                                                    className='border-0 w-100' 
                                                    required
                                                    placeholder='Attribute'
                                                    value={(attribute&&(type===(item?.vital_name?item?.id:item)))?attribute:''}
                                                    onChange={(e)=>setAttribute((item?.vital_name?item?.id:item), e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-9 pe-0'>
                                            <div className='assessment-desc pe-0'>
                                                <input 
                                                    type="text" 
                                                    className='border-0 w-100' 
                                                    required
                                                    placeholder='Description'
                                                    value={(content&&(type===(item?.vital_name?item?.id:item)))?content:''}
                                                    onChange={(e)=>setContent((item?.vital_name?item?.id:item), e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-10 mt-1'></div>
                                        <button onClick={item?.vital_name?handleVitalAdd:handleAdd} className='col assessment-add-btn mt-1'>Add</button>
                                    </form>
                                </>
                            )
                        })}
                        
                    </div>
                </div>
            )}
        </div>
    )
}

export default Accordion